export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'pra'],
  },
  routes: [
    {
      displayName: 'menu.pra',
      permissions: ['admin', 'pra'],
      meta: {
        icon: 'fa6 fa-solid fa-sheet-plastic',
      },
      children: [
        {
          name: 'pra/import',
          displayName: 'menu.pra_import',
          permissions: ['admin', 'pra'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'pra/registration',
          displayName: 'menu.pra_registration',
          permissions: ['admin', 'pra'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'pra/foto',
          displayName: 'menu.pra_foto',
          permissions: ['admin', 'pra'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'pra/export/picture',
          displayName: 'menu.pra_export_picture',
          permissions: ['admin', 'pra'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'pra/export/pass',
          displayName: 'menu.pra_export_pass',
          permissions: ['admin', 'pra'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'pra/export/label',
          displayName: 'menu.pra_export_label',
          permissions: ['admin', 'pra'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
  ],
}
