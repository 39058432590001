<template>
  <div class="embed-layout">
    <router-view />
    <div class="footer">
      <div class="links">
        <a href="https://itf-bruchkoebel.com/de/impressum/">Impressum</a>
        <a href="https://itf-bruchkoebel.com/de/datenschutzerklaerung/">Datenschutz</a>
        <a href="https://itf-bruchkoebel.com/de/kontakt/">Kontakt</a>
      </div>
      <div class="copyright">
        <span>© {{ new Date().getFullYear() }}, Tennis-Club Bruchköbel e.V.</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DefaultLayout",
  components: { },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="scss">
#itf_manager {
  // overflow-y: hidden;
}

.embed-layout {
  width: 100%;
  height: auto !important;
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }

  .footer {
    a {
      font-size: 12px;
      padding: 4px;
    }

    span {
      font-size: 12px;
      padding: 4px;
    }

    .links {
      margin: 10px;
    }

    .copyright  {
      margin: 10px;
    }
  }
}
</style>
