<template>
  <div class="payment-layout">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "PaymentLayout",
  components: { },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="scss">
.payment-layout {
  width: 100%;
  height: auto !important;
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }

  .footer {
    a {
      font-size: 12px;
      padding: 4px;
    }

    span {
      font-size: 12px;
      padding: 4px;
    }

    .links {
      margin: 10px;
    }

    .copyright  {
      margin: 10px;
    }
  }
}
</style>
