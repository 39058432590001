import PRANavigationRoutes from './PRANavigationRoutes'
import ShuttleServiceNavigationRoutes from './ShuttleServiceNavigationRoutes'
import StaffNavigationRoutes from './StaffNavigationRoutes'
import CoachNavigationRoutes from './CoachNavigationRoutes'
import ParcticeCourtNavigationRoutes from './ParcticeCourtNavigationRoutes'
import CMSNavigationRoutes from './CMSNavigationRoutes'
import PaymentNavigationRoutes from './PaymentNavigationRoutes'
import HospitalityNavigationRoutes from './HospitalityNavigationRoutes'
import StringerNavigationRoutes from './StringerNavigationRoutes'
import ExternalNavigationRoutes from './ExternalNavigationRoutes'

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      permissions: [ 'admin', 'dashboard' ],
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      displayName: 'menu.admin',
      permissions: ['admin'],
      meta: {
        icon: 'fa-screwdriver-wrench',
      },
      children: [
        {
          name: 'admin/user',
          displayName: 'menu.user',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'admin/settings',
          displayName: 'menu.settings',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
    {
      displayName: 'menu.general',
      permissions: ['admin', 'general', 'links'],
      meta: {
        icon: 'fa-folder',
      },
      children: [
        {
          name: 'general/overview',
          displayName: 'menu.general_overview',
          permissions: ['admin', 'general'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'general/events',
          displayName: 'menu.events',
          permissions: ['admin', 'general'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'general/members',
          displayName: 'menu.members',
          permissions: ['admin', 'general'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'general/player',
          displayName: 'menu.player',
          permissions: ['admin', 'general'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'general/champions',
          displayName: 'menu.champions',
          permissions: ['admin', 'general'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'general/links',
          displayName: 'menu.links',
          permissions: ['admin', 'general', 'links'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
    {
      displayName: 'menu.media',
      permissions: ['admin', 'media'],
      meta: {
        icon: 'fa-regular fa-images',
      },
      children: [
        {
          name: 'media/general',
          displayName: 'menu.media_general',
          permissions: ['admin', 'media'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
    {
      displayName: 'menu.amvs',
      permissions: ['admin', 'amvs'],
      meta: {
        icon: 'fa-users',
      },
      children: [
        {
          name: 'amvs/overview',
          displayName: 'menu.amvs_overview',
          permissions: ['admin', 'amvs'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'amvs/services',
          displayName: 'menu.services',
          permissions: ['admin', 'amvs'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'amvs/requests',
          displayName: 'menu.requests',
          permissions: ['admin', 'amvs'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'amvs/export',
          displayName: 'menu.request_export',
          permissions: ['admin', 'amvs'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
    {
      displayName: 'menu.livescore',
      permissions: ['admin', 'livescore'],
      meta: {
        icon: 'fa6 fa-solid fa-tv',
      },
      children: [
        {
          name: 'livescore/overview',
          displayName: 'menu.ls_overview',
          permissions: ['admin', 'livescore'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'livescore/draws',
          displayName: 'menu.draws',
          permissions: ['admin', 'livescore'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'livescore/draws/editor',
          displayName: 'menu.draws_editor',
          permissions: ['admin', 'livescore'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'livescore/order-of-plays',
          displayName: 'menu.oops',
          permissions: ['admin', 'livescore'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'livescore/order-of-plays/editor',
          displayName: 'menu.oops_editor',
          permissions: ['admin', 'livescore'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },

    ...PRANavigationRoutes.routes,
    ...PaymentNavigationRoutes.routes,
    ...HospitalityNavigationRoutes.routes,
    ...CoachNavigationRoutes.routes,
    ...ExternalNavigationRoutes.routes,
    ...StaffNavigationRoutes.routes,
    ...ShuttleServiceNavigationRoutes.routes,
    ...ParcticeCourtNavigationRoutes.routes,
    ...CMSNavigationRoutes.routes,
    ...StringerNavigationRoutes.routes,

    {
      name: 'print',
      displayName: 'menu.print',
      permissions: ['admin', 'print'],
      meta: {
        icon: 'fa-print',
      },
      children: [
        {
          name: 'print/player_pass',
          displayName: 'menu.print_player_pass',
          permissions: ['admin', 'print'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },

    {
      name: 'deposit',
      displayName: 'menu.deposit',
      permissions: ['admin', 'deposit', 'deposit-scan'],
      meta: {
        icon: 'fa-money-bill',
      },
      children: [
        {
          name: 'deposit/scan',
          displayName: 'menu.deposit_scan',
          permissions: ['admin', 'deposit', 'deposit-scan']
        },
        {
          name: 'deposit/list',
          displayName: 'menu.deposit_list',
          permissions: ['admin', 'deposit', 'deposit-scan']
        },
      ]
    },

    {
      name: 'notification',
      displayName: 'menu.notification',
      permissions: ['admin', 'notification',],
      meta: {
        icon: 'fa-bell',
      },
      children: [
        {
          name: 'notification/notify',
          displayName: 'menu.notify',
          permissions: ['admin', 'notification']
        },
      ]
    },

    {
      name: 'analytics',
      displayName: 'menu.analytics',
      permissions: ['admin', 'analytics'],
      meta: {
        icon: 'fa-solid fa-chart-line',
      },
      children: [
        {
          name: 'analytics/general',
          displayName: 'menu.analytics_general',
          permissions: ['admin', 'analytics'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'analytics/map',
          displayName: 'menu.analytics_map',
          permissions: ['admin', 'analytics'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'analytics/visitor',
          displayName: 'menu.analytics_visitor',
          permissions: ['admin', 'analytics'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
  ],
}
