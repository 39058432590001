export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'external'],
  },
  routes: [
    {
      displayName: 'menu.external',
      permissions: ['admin', 'external'],
      meta: {
        icon: 'sports',
      },
      children: [
        {
          name: 'external/external',
          displayName: 'menu.external',
          permissions: ['admin', 'external'],
        },
        {
          name: 'external/foto',
          displayName: 'menu.foto',
          permissions: ['admin', 'external'],
        },
      ]
    },
  ],
}
