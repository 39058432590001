export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'cms'],
  },
  routes: [
    {
      displayName: 'menu.cms',
      permissions: ['admin', 'cms'],
      meta: {
        icon: 'fa6 fa-solid fa-file-pen',
      },
      children: [
        {
          name: 'cms/articles',
          displayName: 'menu.cms_articles',
          permissions: ['admin', 'cms'],
        },
        {
          name: 'cms/categories',
          displayName: 'menu.cms_categories',
          permissions: ['admin', 'cms'],
        },
        {
          name: 'cms/tags',
          displayName: 'menu.cms_tags',
          permissions: ['admin', 'cms'],
        },
        {
          name: 'cms/media-library',
          displayName: 'menu.cms_media_library',
          permissions: ['admin', 'cms'],
        }
      ]
    },
  ],
}
