export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'payment'],
  },
  routes: [
    {
      displayName: 'menu.payment',
      permissions: ['admin', 'payment'],
      meta: {
        icon: 'fa6 fa-solid fa-credit-card',
      },
      children: [
        {
          name: 'payment/overview',
          displayName: 'menu.payment_overview',
          permissions: ['admin', 'payment'],
        },
        {
          name: 'payment/transactions',
          displayName: 'menu.payment_transactions',
          permissions: ['admin', 'payment'],
        }
      ]
    },
  ],
}
