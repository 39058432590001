export const checkPermission = ( route_permissions, user_permissions ) => {
  if( route_permissions.length == 0 ) {
    return true;
  }

  for( let permission of route_permissions ) {
    if (user_permissions.indexOf(permission) > -1) {
      return true;
    }
  }

  return false;
}
