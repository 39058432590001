export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'hospitality', 'hospitality-hotel'],
  },
  routes: [
    {
      displayName: 'menu.hospitality',
      permissions: ['admin', 'hospitality', 'hospitality-hotel'],
      meta: {
        icon: 'fa6 fa-solid fa-hotel',
      },
      children: [
        {
          name: 'hospitality/reservations',
          displayName: 'menu.hospitality_reservations',
          permissions: ['admin', 'hospitality'],
        },
        {
          name: 'hospitality/print',
          displayName: 'menu.hospitality_print',
          permissions: ['admin', 'hospitality'],
        },
        {
          name: 'hospitality/hotel',
          displayName: 'menu.hospitality_hotel',
          permissions: ['admin', 'hospitality', 'hospitality-hotel'],
        }
      ]
    },
  ],
}
