export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'practice-court'],
  },
  routes: [
    {
      displayName: 'menu.practice_court',
      permissions: ['admin', 'practice-court'],
      meta: {
        icon: 'fa6 fa-solid fa-person-running',
      },
      children: [
        {
          name: 'practice-courts/overview',
          displayName: 'menu.practice_court_overview',
          permissions: ['admin', 'practice-court'],
        },
        {
          name: 'practice-courts/schedule',
          displayName: 'menu.practice_court_schedule',
          permissions: ['admin', 'practice-court'],
        }
      ]
    },
  ],
}
