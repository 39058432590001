export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'shuttle-service'],
  },
  routes: [
    {
      displayName: 'menu.shuttle_service',
      permissions: ['admin', 'shuttle-service'],
      meta: {
        icon: 'fa6 fa-solid fa-van-shuttle',
      },
      children: [
        {
          name: 'shuttle-service/overview',
          displayName: 'menu.shuttle_service_overview',
          permissions: ['admin', 'shuttle-service'],
        },
        {
          name: 'shuttle-service/cars',
          displayName: 'menu.shuttle_service_cars',
          permissions: ['admin', 'shuttle-service'],
        },
        {
          name: 'shuttle-service/stations',
          displayName: 'menu.shuttle_service_stations',
          permissions: ['admin', 'shuttle-service'],
        },
        {
          name: 'shuttle-service/log',
          displayName: 'menu.shuttle_service_log',
          permissions: ['admin', 'shuttle-service'],
        }
      ]
    },
  ],
}
