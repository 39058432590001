export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'staff'],
  },
  routes: [
    {
      displayName: 'menu.staff',
      permissions: ['admin', 'staff'],
      meta: {
        icon: 'fa6 fa-solid fa-person',
      },
      children: [
        {
          name: 'staff/staff',
          displayName: 'menu.staff',
          permissions: ['admin', 'staff'],
        },
        {
          name: 'staff/role',
          displayName: 'menu.staff_roles',
          permissions: ['admin', 'staff'],
        },
        {
          name: 'staff/foto',
          displayName: 'menu.foto',
          permissions: ['admin', 'staff'],
        },
      ]
    },
  ],
}
