export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
    permissions: ['admin', 'stringer'],
  },
  routes: [
    {
      displayName: 'menu.stringer',
      permissions: ['admin', 'stringer'],
      meta: {
        icon: 'fa6 fa-solid fa-circle',
      },
      children: [
        {
          name: 'stringer/export',
          displayName: 'menu.export',
          permissions: ['admin', 'stringer'],
        },
        {
          name: 'stringer/stringer',
          displayName: 'menu.stringer',
          permissions: ['admin', 'stringer'],
        },
        {
          name: 'stringer/service',
          displayName: 'menu.stringer_service',
          permissions: ['admin', 'stringer'],
        },
      ]
    },
  ],
}
