import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'

import './registerServiceWorker'

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))

import ganttastic from '@infectoone/vue-ganttastic'
app.use(ganttastic)

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();
app.component("font-awesome-icon", FontAwesomeIcon)

/*
if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}
*/

app.config.globalProperties.$methods = {
  resolve(path: string, obj=self, separator='.') {
    let properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev?.[curr], obj)
  }
}

app.config.globalProperties.$copyClipboard = {
  do: ( value: string ) => {
    navigator.clipboard.writeText(value);

    app.config.globalProperties.$vaToast.init({ message: 'Copied: ' + value, color: 'success' });
  }
}

app.config.globalProperties.$validationRules = {
  requiredRule: ( error_message:string ) => {
    const rule = (value:any) => {
      if(value === undefined || value === null) {
        return error_message
      } else {
        return ('' + value).length > 0 || error_message
      }
    }
    return rule;
  },

  dateRule: ( error_message:string ) => {
    const rule = (value:any) => {
      if (!value) {
        return true;
      }
      if(!(value instanceof Date)) {
        return true;
      }

      let year = value.getFullYear();
      let actual_year = new Date().getFullYear()

      return ( year >= actual_year - 120 && year <= actual_year + 120) || error_message;
    }
    return rule;
  },

  requiredRuleVisibleNOT: ( error_message:string, item:any, key:string ) => {
    const rule = (value:any) => {
      if(!item[key]) {
        return true
      }

      if(value === undefined || value === null) {
        return error_message
      } else {
        return ('' + value).length > 0 || error_message
      }
    }
    return rule;
  },

  requiredRuleValuesIn: ( error_message:string, item:any, key:string, values:Array<string> ) => {
    const rule = (value:any) => {
      let _check = null;

      try {
        _check = key.split(/"|'|\]|\.|\[/).reduce((s,c)=>c===""?s:s&&s[c], item)
      } catch (error) {
        return true;
      }

      if(values.includes(_check)) {
        if(value === undefined || value === null) {
          return error_message
        } else {
          return ('' + value).length > 0 || error_message
        }
      } else {
        return true
      }
    }
    return rule;
  }
}

app.mount('#app')
