<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    
    <template #left>
      <RouterLink
        :class="`bg-primary h-full flex items-center justify-center custom-bg bg-${ Math.floor(Math.random() * 6) + 1 }`"
        style="width: calc(100vw - 620px)"
        to="/"
        aria-label="Visit homepage"
      >

        <!-- <VuesticLogo :height="28" start="#FFF" /> -->
        <img src="/img/logo_white.svg" style="max-width: 	60%; opacity: 0.8;"/>
      </RouterLink>
    </template>

    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <!--
            <RouterLink class="py-4" to="/" aria-label="Visit homepage">
              <VuesticLogo class="mb-2" start="#0E41C9" />
            </RouterLink>
            -->
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'

const breakpoint = useBreakpoint()
</script>

<style lang="scss">
  .custom-bg {
    position: relative;
    &:before {
      background-color: var(--va-primary);
      opacity: 0.4;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .bg-1 {
    background-image: url(/img/caro/caro_1.jpg);
    background-size: cover;
  }
  .bg-2 {
    background-image: url(/img/caro/caro_2.jpg);
    background-size: cover;
  }
  .bg-3 {
    background-image: url(/img/caro/caro_3.jpg);
    background-size: cover;
  }
  .bg-4 {
    background-image: url(/img/caro/caro_4.jpg);
    background-size: cover;
  }
  .bg-5 {
    background-image: url(/img/caro/caro_5.jpg);
    background-size: cover;
  }
  .bg-6 {
    background-image: url(/img/caro/caro_6.jpg);
    background-size: cover;
  }
  .bg-7 {
    background-image: url(/img/caro/caro_7.jpg);
    background-size: cover;
  }
</style>